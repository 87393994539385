#faq {
    ul.questions {
        list-style: none;
        margin: 0;
        padding: 0;

        li.question {
            margin-bottom: 15px;
            outline: 0;

            .answer {
                display: none;
                padding: 8px 15px;

                p {
                    margin-bottom: 0;
                }
            }

            .heading {
                display: block;
                background: #EEE;
                font-weight: bold;
                padding: 8px 15px;
                cursor: pointer;
                border-bottom: 1px solid #CCC;
            }

            &.show {
                .answer {
                    display: block;
                }
            }
        }
    }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm) {
}

// *****************************************************************
// MD
// ***************************************************************** 

@media (min-width: $screen-md) {

}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg) {
}

