#objects {
    .sorteren {
        .btn {
            vertical-align: 1px;
            margin-left: 5px;
        }
    }

    .object {
        margin-bottom: 30px;

        a {
            display: block;
            text-decoration: none;
            border: 1px solid #CCC;
            padding: 15px;

            figure {
                img {
                    width: 100%;
                }
            }

            h2 {
                font-weight: normal;
                margin-bottom: 0;
            }

            h3 {
                margin-bottom: 0;
            }

            &:hover {
                background: #f1f2f2;
            }
        }
    }

    #maps {
        height: 500px;
        margin-bottom: 40px;

        h3 {
            margin: 0;
        }
    }

    #searchForm {
        .select2 {
            input {
                height: 24px;
            }
        }
    }
}

// *****************************************************************
// MD
// *****************************************************************

@media (min-width: $screen-md-min) {
}