#footer_wrapper {
    background: #fff;
    color: $color_blue;
    font-size: 14px;
    padding-top: 30px;
    box-shadow: 0 -5px 20px rgba(17, 25, 65, .1);

    h2 {
        font-weight: 400;
        font-size: 12px;
        color: $color_blue;
        margin: 0 0 10px 0;
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        font-weight: bold;
    }

    a {
        position: relative;
        color: $color_blue;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        &:not(.social) {
            a {
                padding-left: 10px;

                &:before {
                    content: '\f105';
                    font-family: 'icomoon';
                    position: absolute;
                    left: 0;
                    top: 0;
                    color: $color_orange;
                }
            }
        }
    }

    address {
        font-style: normal;
        margin-bottom: 0;
    }

    .footer_bottom {
        margin-top: 20px;
        border-top: 1px solid #CCC;
        padding: 20px 0;
    }

    .social {
        li {
            display: block;
            float: left;
            margin-bottom: 10px;

            &:not(:last-child) {
                margin-right: 10px;
            }

            a {
                display: block;
                color: $color_blue;
                text-decoration: none;
                width: 50px;
                height: 50px;
                text-align: center;
                line-height: 50px;
                font-size: 22px;
                border-radius: 3px;
                transition: all 0.3s;

                border-radius: 50%;
                border: 2px solid $color_blue;
                
                &:hover {
                    transform: translateY(-2px);
                }
            }

            // &.social_rss {
            //     a {
            //         background: #ff9900;
            //         font-size: 18px;

            //         &:hover {
            //             background: shade(#ff9900, 10%);
            //         }
            //     }
            // }

            // &.social_facebook {
            //     a {
            //         background: #3c5a99;

            //         &:hover {
            //             background: shade(#3c5a99, 10%);
            //         }
            //     }
            // }

            // &.social_twitter {
            //     a {
            //         background: #019cf6;

            //         &:hover {
            //             background: shade(#019cf6, 10%);
            //         }
            //     }
            // }

            // &.social_youtube {
            //     a {
            //         background: #e62119;

            //         &:hover {
            //             background: shade(#e62119, 10%);
            //         }
            //     }
            // }

            // &.social_linkedin {
            //     a {
            //         background: #0177b5;

            //         &:hover {
            //             background: shade(#0177b5, 10%);
            //         }
            //     }
            // }
        }
    }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm-min) {

    #footer_wrapper {
        .social_wrapper {
            float: right;
        }
    }
}

@media (min-width: $screen-md-min) {
    #footer_wrapper {
        padding: 75px 0;
    }
}