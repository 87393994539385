.button,
.btn {
  display: inline-block;
  border-radius: 5px;
  padding: 9px 20px;
  text-decoration: none !important;
  border: none;
  font-size: 14px;
  transition: all 300ms ease;

  &:hover {
    text-decoration: none;
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(#000, .1);
  }

  &.small {
    padding: 2px 10px;
  }

  &.orange,
  &.btn-primary {
    background: $color_orange;
    color: $color_blue;

    &:hover {
      background: darken($color_orange, 5%);
    }
  }
}