.form_wrapper {
    background: #F5F5F5;
    padding: 15px;
    margin-bottom: 30px;
}

.form-horizontal {
    .control-label {
        text-align: left;
    }

    .g-recaptcha {
        margin-left: 15px;

        + noscript + .help-block {
            margin-left: 15px;
        }
    }

    @media (min-width: $screen-sm-min) {
        .g-recaptcha {
            margin-left: calc(25% + 15px);

            + noscript + .help-block {
                margin-left: calc(25% + 15px);
            }
        }
    }
}

input {
    &[type="text"] {
        border: 1px solid #E2E2E2;
        border-radius: 3px;
        box-shadow: none;
        padding: 8px 12px;
        height: auto;
    }

    &[type="checkbox"] {
        position: relative;
        appearance: none;
        height: 20px;
        width: 20px;
        border: 1px solid #E2E2E2;
        transition: all 300ms ease;
        border-radius: 3px;
        margin: 3px 0 0 0;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:checked {
            background: $color_orange;
            border-color: $color_orange;

            &:before {
                content: '\f00c';
                font-family: 'FontAwesome';
                position: absolute;
                color: #fff;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}