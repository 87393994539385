@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?ozn8z0');
    src:  url('fonts/icomoon.eot?ozn8z0#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ozn8z0') format('truetype'),
    url('fonts/icomoon.woff?ozn8z0') format('woff'),
    url('fonts/icomoon.svg?ozn8z0#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-check-circle-solid:before {
    content: "\e903";
}
.icon-phone:before {
    content: "\e942";
}
.icon-linkedin22:before {
    content: "\eacb";
}
.icon-facebook:before {
    content: "\ea90";
}
.icon-twitter:before {
    content: "\ea96";
}
.icon-rss:before {
    content: "\ea9b";
}
.icon-youtube:before {
    content: "\ea9d";
}
.icon-linkedin2:before {
    content: "\eaca";
}
.icon-netwerk-kennisgroepen:before {
    content: "\e904";
}
.icon-veelgestelde-vragen:before {
    content: "\e905";
}
.icon-werk-documenten:before {
    content: "\e906";
}
.icon-belangen-beharting:before {
    content: "\e907";
}
.icon-crm-databases:before {
    content: "\e908";
}
.icon-juridische-ondersteuning:before {
    content: "\e909";
}
.icon-kennis-educatie:before {
    content: "\e90a";
}
.icon-ledenvoordeel:before {
    content: "\e90b";
}
.icon-marketing-ondersteuning:before {
    content: "\e90c";
}
.icon-envelope:before {
    content: "\f0e0";
}
.icon-angle-right:before {
    content: "\f105";
}
.icon-user:before {
    content: "\e902";
}
.icon-menu:before {
    content: "\e900";
}
.icon-search:before {
    content: "\e901";
}
.icon-calendar:before {
    content: "\e916";
}
.icon-question-circle:before {
    content: "\e92b";
}
