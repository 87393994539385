#login {
    background: #f5f5f5;
    width: 100%;

    > .row {
        display: flex;
        flex-wrap: wrap;
        flex: 1;

        .col-xs-12 {
            flex: 0 0 100%;
            height: 100%;
        }
    }

    .panel {
        background: #fff;
        box-shadow: 0 10px 20px rgba($color_blue, .1);
        max-height: 540px;
        max-width: 580px;
        padding: 30px;
        margin: 30px 15px;

        h1 {
            font-size: 22px;
            font-weight: 900;
            color: $color_blue;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
            margin-top: 0;
            text-align: center;
        }

        form {
            .form-group {
                position: relative;
                padding-top: 30px;

                label {
                    position: absolute;
                    font-weight: normal;
                    color: $color_blue;
                    top: 47.5px;
                    left: 25px;
                    transition: all 300ms ease;

                    &.active {
                        left: 0;
                        top: 0;
                    }
                }
            }

            input {
                padding: 19px 25px;
                box-shadow: 0 3px 6px rgba($color_blue, .1);
                border: none;
                height: auto;

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus {
                    border: none;
                    -webkit-text-fill-color: $color_blue;
                    -webkit-box-shadow: 0 0 0px 1000px #fff inset, 0 3px 6px rgba($color_blue, .1);
                    transition: background-color 5000s ease-in-out 0s;
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #login {
        display: flex;
        min-height: calc(100vh - 360px);

        > .row {
            .col-xs-12 {
                display: flex;
                justify-content: center;
                align-items: center;

            }
        }

        .panel {
            margin: 0;
            width: 100%;
        }
    }
}

@media (min-width: $screen-sm-min) {
    #login {
        height: calc(100vh - 216px);

        > .row {
            .col-xs-12 {
                position: relative;
                flex: 0 0 50%;
            }
        }

        .panel {
            margin: 0 15px;

            h1 {
                font-size: 40px;
            }
        }

        .background {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: url('/img/login/background.jpg') no-repeat center center;
            background-size: cover;
        }
    }
}