#object {
    #objectheader {
        h1 {
            margin-bottom: 0px;
        }

        h2 {
            font-weight: 400;
        }

        ul {
            border-top: 1px solid #CCC;
            padding-top: 15px;
            margin-bottom: 30px;
        }

        .buttons {
            position: absolute;
            right: 15px;
            bottom: 15px;

            a:first-of-type {
                margin-right: 8px;
            }
        }
    }

    .makelaar {
        background: #F5F5F5;
        padding: 15px;
        margin-bottom: 20px;

        h2 {
            font-weight: normal;
            margin-bottom: 10px;
        }

        address {
            font-size: 12px;
            margin-bottom: 0;
        }
    }

    #fotos {
        figure {
            margin-bottom: 20px;

            img {
                width: 100%;
            }
        }
    }

    #kenmerken {
        h4 {
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: bold;
        }

        h5 {
            font-weight: bold;
        }

        dl {
            width: 100%;
            margin: 0 0 50px 0;
            border-bottom: 1px solid #CCC;

            dd, dt {
                border-top: 1px solid #CCC;
                display: inline-block;
                width: 50%;
                line-height: 40px;
                margin: 0px;
                font-weight: normal;
            }

            + h5 {
                margin-top: -22px;
            }
        }
    }

    iframe {
        width: 100%;
    }
}

// *****************************************************************
// MD
// *****************************************************************

@media (min-width: $screen-md-min) {
    #object {
        #objectheader {
            h2 {
                .price {
                    float: right;
                }
            }
        }
    }
}