#header_wrapper {
    position: relative;
    z-index: 4;

    // &:before {
    //     position: relative;
    //     z-index: 100;
    //     content: " ";
    //     display: block;
    //     width: 100%;
    //     height: 6px;
    //     background: $color-orange;
    //     border-top: 6px solid $color-blue;
    // }

    header {
        position: relative;
        z-index: 10;
        height: 60px;
        background: #FFF;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
        border-bottom: 5px solid $color_orange;

        img.logo {
            display: block;
            height: 32px;
            margin: 9px 0;
        }

        button {
            position: absolute;
            top: 0;
            right: 0px;
            width: 50px;
            height: 50px;
            margin: 0;
            padding: 0;
            border: 0;
            outline: none;
            font-size: 24px;
            color: #666;
            border-left: 1px solid #CCC;
            background: #FFF;

            &#btn_user {
                right: 50px;
            }

            &#btn_search {
                right: 100px;
            }

            &:hover {
                background: #F5F5F5;
                color: $color-orange;
            }
        }

    }

    > .container-fluid {
        position: relative;
        padding: 0;
    }

    .subwrapper {
        display: none;
        padding: 15px;
        background: $color_blue;

        &#nav_wrapper {
            padding: 0;
            border-bottom: none;

            > .container-fluid {
                padding: 0;
            }
        }

        nav {
            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    a {
                        padding: 10px 15px;
                        display: block;
                        background: #FFF;
                        color: #666;
                        text-decoration: none;
                        border-left: 4px solid #CCC;
                        border-bottom: 1px solid #CCC;
                    }
                }
            }

            > ul {
                > li {
                    > ul {
                        display: none;

                        > li {

                            a {
                                background: #F5F5F5;

                                &:before {
                                    content: '- ';
                                }

                                &:hover {
                                    background: #EEE;
                                }
                            }

                            &.selected {
                                > a {
                                    background: #EEE;
                                }
                            }
                        }

                        &.cols {
                            > li {
                                > a {
                                    font-size: 14px;
                                    font-weight: bold;

                                    &:before {
                                        content: none;
                                    }
                                }
                            }
                        }
                    }

                    &.selected {
                        > ul {
                            display: block;
                        }
                    }
                }
            }
        }

        form {
            position: relative;

            input[type="text"] {
                width: 100%;
                padding: 8px 10px;
                border-radius: 3px;
                border: 1px solid #E2E2E2;
                font-size: 14px;
                color: $color_blue;

                &::placeholder {
                    color: $color_blue;
                }
            }

            &.search {
                button {
                    position: absolute;
                    top: 3px;
                    right: 0px;
                    font-size: 14px;
                    background: none;
                    border: none;
                    color: $color_blue;
                    padding: 5px 10px;
                }
            }

            &.login {
                input {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

#overstapMessage {
    background: #BBE5A9;
    text-align: center;
    padding: 20px 0;
    color: $color_blue;

    a {
        color: #304191;
        text-decoration: underline;
    }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm-min) {
    #header_wrapper {
        &:before {
            border-top: 10px solid $color-blue;
            height: 10px;
        }

        header {
            box-shadow: none;

            img.logo {
                height: 40px;
                float: left;
            }

            h2 {
                float: left;
                color: $color-blue;
                margin: 18px 0 0 15px;
                font-size: 14px;
                font-weight: normal;
                padding-left: 15px;
                font-style: italic;
            }

            .metanav {
                position: absolute;
                right: 220px;
                top: 10px;
                list-style: none;
                padding: 5px 10px;
                border-radius: 3px;
                background: #F5F5F5;
                margin-bottom: 0;

                li {
                    display: inline-block;
                    font-size: 14px;

                    &:not(:last-child) {
                        border-right: 1px solid #CCC;
                        padding-right: 10px;
                        margin-right: 6px;
                    }

                    a {
                        color: #666;
                    }
                }
            }

            button#btn_user {
                border: none;
                right: 15px;
                font-size: 14px;
                background: $color_orange;
                color: $color_blue;
                padding: 9px 45px 9px 20px;
                border-radius: 5px;
                top: 7px;
                width: 180px;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                height: auto;

                span.icon-user {
                    margin-right: 5px;
                }

                &:after {
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    transform: translateY(-50%);
                    content: " ";
                    display: block;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid $color_blue
                }
            }
        }

        .subwrapper {
            display: block;
            height: 50px;

            &#nav_wrapper {
                > .container-fluid {
                    padding: 0 15px;
                }
            }

            nav {
                > ul {
                    display: flex;

                    > li {
                        position: relative;
                        height: 100%;
                        display: block;
                        float: left;
                        width: 15%;
                        font-size: 14px;

                        > a {
                            position: relative;
                            display: block;
                            height: 100%;
                            border: none;
                            text-align: center;
                            padding: 14px 0;
                            background: none;
                            color: #fff;

                            &:after {
                                content: ' ';
                                position: absolute;
                                top: 25%;
                                right: 0;
                                height: 50%;
                                width: 1px;
                                background: rgba(#fff, .3);
                            }
                        }

                        // subnav
                        span.triangle {
                            display: none;
                        }

                        > ul {
                            display: block;
                            position: absolute;
                            top: 100%;
                            left: 0;
                            z-index: 1;
                            min-width: 100%;
                            max-height: 0px;
                            overflow: hidden;
                            transition: all 300ms linear;
                            box-shadow: 0 10px 20px rgba(#000, .05);
                            padding: 0px 30px;
                            background: #FFF;

                            li {
                                display: inline-block;
                                width: 100%;
                                position: relative;
                                float: none;
                                padding: 0;
                                border-bottom: 1px solid #EEE;
                                break-inside: avoid-column;

                                &:last-child {
                                    border-bottom: 0;
                                }

                                span.triangle {
                                    display: none !important;
                                }

                                a {
                                    display: block;
                                    white-space: nowrap;
                                    border: none !important;
                                    background: none;
                                    text-align: left;
                                    color: $color_blue;
                                    padding: 8px 0;
                                    transition: all 300ms ease;
                                    font-size: 16px;

                                    &:after {
                                        content: none;
                                    }

                                    &:before {
                                        display: inline-block;
                                        margin-right: 5px;
                                        font-family: 'icomoon' !important;
                                        speak: never;
                                        font-style: normal;
                                        font-weight: normal;
                                        font-variant: normal;
                                        text-transform: none;
                                        line-height: 1;
                                        -webkit-font-smoothing: antialiased;
                                        -moz-osx-font-smoothing: grayscale;
                                        content: "\f105";
                                        color: $color-orange;
                                    }

                                    &:hover {
                                        background: none;
                                        color: $color-orange;
                                    }
                                }

                                &.selected {
                                    border-bottom: 1px solid $color-orange;

                                    > a {
                                        background: none;
                                        color: $color-orange;
                                    }
                                }
                            }

                            &.cols {
                                //columns: 2;
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                column-gap: 40px;
                                row-gap: 0;

                                > li {
                                    border-bottom: 0;

                                    > a {
                                        text-transform: uppercase;
                                        padding-bottom: 0;
                                    }

                                    > ul {
                                        margin-bottom: 15px;
                                    }
                                }
                            }
                        }

                        &:after {
                            position: absolute;
                            left: 0px;
                            bottom: 0px;
                            display: block;
                            content: " ";
                            height: 4px;
                            width: 100%;
                            background: transparent;
                            transition: all 0.3s;
                        }

                        &.selected {
                            &:after {
                                background: $color-orange;
                            }
                        }

                        &:hover {
                            span.triangle {
                                display: block;
                                content: ' ';
                                position: absolute;
                                top: 100%;
                                left: 50%;
                                z-index: 2;
                                width: 0;
                                height: 0;
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                border-top: 5px solid $color_orange;
                            }

                            > ul {
                                max-height: 700px;
                                padding: 15px 30px;
                            }

                            &:after {
                                background: $color_orange;
                            }
                        }
                    }
                }
            }

            &#search_wrapper {
                padding: 0;
                border: 0;
                background: none;
                position: absolute;
                right: 215px;
                top: -103px;
                z-index: 100;

                form {
                    input {
                        padding: 0 15px;
                        width: 250px;
                        line-height: 38px;
                    }

                    button {
                        top: 0px;
                        padding: 10px 15px;
                    }
                }

                .flash-error {
                    position: absolute;
                    display: block;
                    font-size: 12px;
                    color: #a94442;
                    background: #f2dede;
                    border: 1px solid #ebccd1;
                    padding: 5px;
                    cursor: pointer;
                }
            }

            &#user_wrapper {
                display: none;
                position: absolute;
                right: 15px;
                top: -52px;
                z-index: 100;
                border-radius: 3px;
                background: #fff;
                box-shadow: 0 10px 20px rgba(#000, .1);
                height: auto;

                &:before {
                    position: absolute;
                    right: 45px;
                    top: -8px;
                    content: " ";
                    display: block;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 8px solid #CCC;
                }

                &:after {
                    position: absolute;
                    right: 45px;
                    top: -6px;
                    content: " ";
                    display: block;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 8px solid #EEE;
                }

                form {
                    input {
                        // display: block;
                        // padding: 6px 10px;
                        // width: 200px;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}

// *****************************************************************
// MD
// *****************************************************************

@media (min-width: $screen-md-min) {

}

// *****************************************************************
// LG
// *****************************************************************

@media (min-width: $screen-lg-min) {

}
